/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import data from "./data/cartadigital";

const render = (params) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let id = urlParams.get('id');
    console.log(id);
    import(`./assets/css/sass/main.scss`).then(() => {
        data.load(id).then(()=>{           
            require('./AppRenderer');
        })
    })
  
};
render();
