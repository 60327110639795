import axios from 'axios';

let __data = {}

const load = async (id) => 
    axios
        .get("/data/"+id+".json")
        .then(res => {
            let str = res.data;
            console.log(str);
            __data = res.data;
        return true;
});

const get = () =>{
    return __data;
}

export default {
    load,
    get
}